/* global msal */
/* eslint no-throw-literal: 0 */
import Common from "../util/Common";

export default class Authenticator {
    static async load(){
        await Common.loadScript('https://insightscdn.azureedge.net/web-assets/msal2.35.min.js');
    }

    constructor(){      
        this.mode = 'Enterprise';
        this.loginStyle = 'popup';

        this.clientId = '604417e0-0d21-48fa-895a-1b0a2e9a3db6';
        this.config = {
            auth: {
                clientId: this.clientId,
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: window.location.origin,
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {	
                            return;	
                        }	
                        switch (level) {	
                            case msal.LogLevel.Error:	
                                console.error(message);	
                                return;	
                            case msal.LogLevel.Info:	
                                console.info(message);	
                                return;	
                            case msal.LogLevel.Verbose:	
                                console.debug(message);	
                                return;	
                            case msal.LogLevel.Warning:	
                                console.warn(message);	
                                return;	
                            default: 
                                return;
                        }
                    }
                }
            }
        };

        this.MSALObj = new msal.PublicClientApplication(this.config);     
        
        this.account = null;

        let usernamePref = window.sessionStorage.getItem('rm.username');
        const currentAccounts = this.MSALObj.getAllAccounts();
        if (currentAccounts !== null && currentAccounts.length !== 0) {
            this.account = currentAccounts.find(account => {
                return account.username === usernamePref;
            });      
        }

        this.switchAccount = this.switchAccount.bind(this);
        this.logout = this.logout.bind(this);
    }
    async login(prompt) {
        let loginRequest = {
            scopes: ["User.Read"]
        };

        let _ = await this.MSALObj.handleRedirectPromise();

        if (prompt)loginRequest.prompt = prompt;
        let loginResponse = await this.MSALObj.loginPopup(loginRequest);
        if (loginResponse){
            let username = loginResponse.account.username;
            let prefUsername = loginResponse.idTokenClaims?loginResponse.idTokenClaims.preferred_username:username;

            window.sessionStorage.setItem('rm.username', username);
            window.sessionStorage.setItem('rm.preferred_username', prefUsername);

            this.account = loginResponse.account;

            if (prompt && prompt !== 'none'){
                window.location = window.location.origin;
            }
        }else{
            throw this.loginFailure();
        }
    }

    switchAccount(){
        this.login('select_account');
    }
    
    logout() {
        window.sessionStorage.removeItem('rm.login_mode');
        const logoutRequest = {
            account: this.account || null
        };
    
        this.MSALObj.logout(logoutRequest);
    }

    async isAuthenticated(){
        return !!this.account;
    }

    acquireToken() {
        let self = this;
        let request = {
            scopes: [`${this.clientId}/.default`],
            forceRefresh: false
        };

        request.account = this.account;
        if (request.account){
            let prefUsername = window.sessionStorage.getItem('rm.preferred_username');
            if (prefUsername && prefUsername !== this.account.username){
                request.loginHint = prefUsername;
            }
        }

        return new Promise((resolve, reject) => {     
            this.MSALObj.acquireTokenSilent(request)
                .then(tokenResponse => {
                    resolve('Bearer ' + tokenResponse.accessToken);        
                })
                .catch(error => {
                    console.warn("Silent token acquisition failed. Acquiring token using popup.");
                    if (error instanceof msal.InteractionRequiredAuthError) {
                        request.prompt = 'select_account';
                        return this.MSALObj.acquireTokenPopup(request).then(tokenResponse2 => {
                            resolve('Bearer ' + tokenResponse2.accessToken);
                        }).catch(error2 => {
                            reject(self.tokenFailure(error2));
                        });
                    } else {
                        reject(self.tokenFailure(error));
                    }
                });
        });
    }

    loginFailure(error){    
        return { message: 'Login failed. Possibly cancelled by user or popup blocked.'};
    }

    tokenFailure(error){    
        return { message: 'Token acquisition failed. ' + error.toString(), name: 'acquisitionFailure'};
    }

    getUsername(){
        let account = this.account;        
        return account.username;
    }
}
